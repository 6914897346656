import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { User } from 'src/app//core/models/user';
import { UserService } from '@core/services/user.service';
import { ThemeService } from '@core/services/theme.service';
import { TenableChildAccount } from '@core/tenable/models/tenable-child-account';
import { BehaviorSubject, Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  // showProfileMenu = false;
  showNotyMenu = false;
  sideBarvisible = false;
  userModel: User | null = null;
  mspAccount?: TenableChildAccount | null;

  randomStrings = [

    {
      level: "Alert",
      message: "Alert: Critical Vulnerability Detected",
    },
    {
      level: "Warning",
      message: "Warning: High-Risk Vulnerability Found",
    },
    {
      level: "Notice",
      message: "Notice: Medium-Risk Vulnerability",
    },
    {
      level: "Info",
      message: "Info: Low-Risk Vulnerability Identified",
    },
    {
      level: "Reminder",
      message: "Reminder: Vulnerability Scan Completed",
    },
    {
      level: "Alert",
      message: "Alert: Exploit Detected",
    },
    {
      level: "Warning",
      message: "Warning: Outdated Software Vulnerability",
    },
    {
      level: "Notice",
      message: "Notice: Patch Available for Vulnerability",
    },
    {
      level: "Info",
      message: "Info: Vulnerability Scan Scheduled",
    },
    {
      level: "Reminder",
      message: "Reminder: Review Vulnerability Report",
    },
    {
      level: "Alert",
      message: "Alert: Critical Vulnerability Detected",
    },
    {
      level: "Warning",
      message: "Warning: High-Risk Vulnerability Found",
    },
    {
      level: "Notice",
      message: "Notice: Medium-Risk Vulnerability",
    },
    {
      level: "Info",
      message: "Info: Low-Risk Vulnerability Identified",
    },
    {
      level: "Reminder",
      message: "Reminder: Vulnerability Scan Completed",
    },
    {
      level: "Alert",
      message: "Alert: Exploit Detected",
    },
    {
      level: "Warning",
      message: "Warning: Outdated Software Vulnerability",
    },
    {
      level: "Notice",
      message: "Notice: Patch Available for Vulnerability",
    },
    {
      level: "Info",
      message: "Info: Vulnerability Scan Scheduled",
    },
    {
      level: "Reminder",
      message: "Reminder: Review Vulnerability Report"
    }
  ]


  remainingTime: { hours: number, minutes: number, seconds: number } = { hours: 0, minutes: 0, seconds: 0 };
  countdownSubscription: Subscription | null = null;


  @ViewChild('notyButton') notyButton!: ElementRef;
  @ViewChild('notyMenu') notyMenu!: ElementRef;
  @ViewChild('profileBtn') profileBtn!: ElementRef;
  @ViewChild('profileMenu') profileMenu!: ElementRef;

  constructor(
    private themeService: ThemeService,
    private userService: UserService,
    private renderer: Renderer2
  ) {
    // https://stackoverflow.com/questions/51150422/how-to-detect-click-outside-of-an-elem`ent-in-angular
    this.renderer.listen('window', 'click', (e: Event) => {

      // // Profile Links
      // if (!this.profileMenu.nativeElement.contains(e.target)
      // && !this.profileBtn.nativeElement.contains(e.target)
      // ) {
      //   this.showProfileMenu = false;
      // }

      // Notification Buttons
      if (this.notyButton && this.notyMenu) {

        if (!this.notyButton.nativeElement.contains(e.target) && !this.notyMenu.nativeElement.contains(e.target)) {
          this.showNotyMenu = false;

        }
      }
    });
    this.themeService.getSidebarIsVisable().subscribe((value: boolean) => {
      this.sideBarvisible = value;
    });
  }

  ngOnInit(): void {
    this.userService.getUserModel().subscribe((user) => {
      if (user && user.uid) {
        this.userModel = user;
        this.initDarkMode();
      }
    });
    this.themeService.getAccountModel().subscribe(mspAccount => {
      if (mspAccount) {
        this.mspAccount = mspAccount;
        if (this.isExpiringSoon(mspAccount.license_expiration_date)) {

          this.countdownSubscription = interval(1000).subscribe(() => {
            if (this.mspAccount)
              this.updateCountdown(this.mspAccount.license_expiration_date * 1000);
          });
        }
      }
    });
    this.getAccountDetails();
  }

  ngOnDestroy(): void {
    // Clean up the subscription when the component is destroyed
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }


  showProfileMenu: boolean = false;

  toggleMenu() {
    this.showProfileMenu = !this.showProfileMenu;
    if (this.showProfileMenu) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  showMenu() {
    this.showProfileMenu = true;
    // this.showProfileMenu = !this.showProfileMenu;
  }

  hideMenu() {
    this.showProfileMenu = false;
  }


  /**
   * Closes the profile menu when clicking outside of it.
   * @param event MouseEvent to detect the click location.
   */
  @HostListener('document:click', ['$event'])
  closeMenuOnOutsideClick(event: MouseEvent) {
    // Check if click target is outside the profile button and menu
    if (this.showProfileMenu && this.profileBtn && !this.profileBtn.nativeElement.contains(event.target)) {
      this.showProfileMenu = false;
    }
  }


  showNotifications() {
    this.showNotyMenu = !this.showNotyMenu;
  }

  toggleSidebar() {
    this.themeService.toggleSidebarVisibility();
  }

  getAccountDetails() {
    const mspAccountData = localStorage.getItem('accountDetails');
    if (mspAccountData) {
      this.mspAccount = new TenableChildAccount(JSON.parse(mspAccountData));
    }

  }

  // Initialize dark mode BehaviorSubject with a default value of true
  dark: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /**
   * Initializes the dark mode setting for the application.
   * If a user profile is available, it sets the dark mode according to the user's theme preference.
   * Defaults to dark mode if no preference is specified.
   */
  initDarkMode() {
    // Get the dark mode setting from the user profile, if available
    if (this.userModel && this.userModel.themeColor) {
      this.dark.next(this.userModel.themeColor === 'dark');
    }

    // Set the theme based on the current value of dark mode
    document.body.setAttribute('data-bs-theme', this.dark.value ? 'dark' : 'light');
    this.themeService.setTheme(this.dark.value ? 'dark' : 'light');
  }

  /**
   * Toggles the dark mode setting for the application.
   * This function switches the current theme between dark and light modes,
   * updates the body's `data-bs-theme` attribute, and calls the user service to save the new preference.
   */
  toggleDarkMode() {
    // Toggle dark mode value
    const newDarkModeValue = !this.dark.value;
    this.dark.next(newDarkModeValue);

    // Update theme attribute on the body
    document.body.setAttribute('data-bs-theme', newDarkModeValue ? 'dark' : 'light');

    // Update the user profile theme setting if applicable
    this.userService.updateUserTheme(newDarkModeValue ? 'dark' : 'light');
    this.themeService.setTheme(newDarkModeValue ? 'dark' : 'light');
    this.hideMenu();
  }



  getClassByLevel(level: string) {
    switch (level) {
      case 'Alert':
        return 'alert-danger';
      case 'Warning':
        return 'alert-warning';
      case 'Notice':
        return 'alert-primary';
      case 'Info':
        return 'alert-info';
      case 'Reminder':
        return 'alert-secondary';
      default:
        return 'alert-primary';
    }
  }



  // Check if the expiration date is within 1 day of the current date
  // Helper function to determine if the license is expiring soon (within 24 hours)
  isExpiringSoon(expirationDate: number): boolean {
    const currentTime = new Date().getTime();
    const remainingTime = expirationDate * 1000 - currentTime;
    return remainingTime <= 24 * 60 * 60; // 24 hours
  }


  // Get the remaining time until the expiration date for a countdown hours and mins
  getRemainingTime(expirationDate: number) {
    const currentTime = new Date().getTime();
    const remainingTime = expirationDate * 1000 - currentTime;

    // Calculate hours and minutes
    const hours = Math.floor(remainingTime / (60 * 60));
    const minutes = Math.floor((remainingTime % (60 * 60)) / (60));

    return { hours, minutes };
  }


  updateCountdown(expirationDate: number) {

    const currentTime = new Date().getTime();
    const remainingTime = expirationDate * 1000 - currentTime;

    // Stop the countdown when time runs out
    if (remainingTime <= 0) {
      this.remainingTime = { hours: 0, minutes: 0, seconds: 0 };
      return;
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(remainingTime / (60 * 60));
    const minutes = Math.floor((remainingTime % (60 * 60)) / (60));
    const seconds = Math.floor((remainingTime % (60)) / 1000);

    this.remainingTime = { hours, minutes, seconds };
  }


}
